import { ExperienceCardProps } from "../../../../components"

export const cruiser30a: ExperienceCardProps = {
  category: "bikes",
  description: "Welcome to Cruiser 30A Electric Bike Rentals, your premium electric bike rentals in the beautiful 30A coastal region. Our small business is dedicated to providing an exceptional experience for locals and visitors, combining the comfort and style of cruiser and fat tire bikes with the convenience of electric power. At Cruiser 30A Electric Bike Rentals, we specialize in offering a wide selection of electric bikes, designed for leisurely rides along the breathtaking 30A coastline. Our bikes feature classic designs, comfortable seating, and effortless electric assistance, allowing riders of all skill levels to enjoy a relaxing and scenic journey. To make your experience even more convenient, we provide free delivery service directly to your preferred location within the 30A area. Whether you’re staying at a vacation rental, hotel, or beachfront property, our team will bring the  electric bikes to you, saving you time and effort. As a special offer, we extend a generous 15% discount on all our cruiser electric bike rentals. We believe in making exploration accessible and affordable, ensuring you can experience the beauty of 30A. We are always available to assist you in selecting the perfect electric bike for your adventure. We’ll provide comprehensive instructions on bike operation, share safety guidelines, and offer insider recommendations for the best routes, local trails, and local attractions along 30A. Discover the pleasure of cruising the 30A coastline in style with our electric bikes. Contact Cruiser 30A Electric Bike Rentals today to take advantage of our free delivery and enjoy a fantastic 15% discount, enhancing your exploration of this enchanting coastal region.",
  email: "cruiser30a@gmail.com",
  id: '4a3987c8-23bf-40da-80ab-986d04243e4b',
  images: [
    "experiences/cruiser30a/bike3.jpg",
    "experiences/cruiser30a/bike1.jpg",
    "experiences/cruiser30a/bike2.jpg",
    "experiences/cruiser30a/bikes1.jpg",
    "experiences/cruiser30a/bikes2.jpg",
  ],
  title: "Cruiser 30a",
  website: "https://cruiser30a.com",
}