import { ExperienceCardProps } from "../../../../components"

export const yacht: ExperienceCardProps = {
  category: "boats",
  description: "30a Yacht Charters offers Executive Yacht Charters on 52-75 foot luxury yachts.  These Luxury Yachts can comfortably accommodate up to 12 guests plus staff.  Yacht Charters include 4, 6 and 8 hour charters.   We also offer Deep Sea Fishing Charters on a 47 foot Buddy Davis Yacht, and Inshore fishing charters on a 32 foot center console.   30a Yacht Charters also offers luxury pontoon rentals.  Our luxury pontoon rentals are 150, 200, and 350 hp tritoons as well as 150 and 200 hp double decker tritoons with water slides.",
  email: "30arentalcompany@gmail.com",
  id: 'b9aa1a7e-e98c-4095-b5e3-2a1a392a9043',
  images: [
    "experiences/30ayachtcharters/yacht2.jpg",
    "experiences/30ayachtcharters/fishing1.jpg",
    "experiences/30ayachtcharters/fishing2.jpg",
    "experiences/30ayachtcharters/party1.jpg",
    "experiences/30ayachtcharters/party2.jpg",
    "experiences/30ayachtcharters/party3.jpg",
    "experiences/30ayachtcharters/yacht1.jpg",
    "experiences/30ayachtcharters/yacht3.jpg",
  ],
  phone: "850-220-6400",
  title: "30a Yacht Charters",
  website: "https://www.30ayachtcharters.com",
}