import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LoginIcon from '@mui/icons-material/Login'
import MenuIcon from '@mui/icons-material/Menu'
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box, 
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { entertainmentItems, foodItems, rentalItems, shoppingItems } from '../categories'

const drawerWidth = 240

const categories = [
  [ "Entertainment", entertainmentItems ] as const,
  [ "Food", foodItems ] as const,
  [ "Rentals", rentalItems ] as const,
  [ "Shopping", shoppingItems ] as const,
]

export const ResponsiveDrawer = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const [ mobileOpen, setMobileOpen ] = React.useState(false)
  const [ expanded, setExpanded ] = React.useState<string | false>(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      {categories.map(([ title, subcategories ]) => {
        return (
          <Accordion expanded={expanded === title} onChange={handleChange(title)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${title.toLowerCase()}-content`}
              id={`${title.toLowerCase()}-header`}
            >
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {subcategories.map((category) => (
                  <ListItem key={category.name} disablePadding onClick={() => {
                    handleDrawerToggle()
                    navigate(`/categories/${category.category}`)
                  }}>
                    <ListItemButton>
                      <ListItemIcon>
                        <category.icon />
                      </ListItemIcon>
                      <ListItemText primary={category.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )
      })}
      <Divider />
      <List>
        <ListItem key={1} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <LoginIcon/>
            </ListItemIcon>
            <ListItemText primary={"Login"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" onClick={() => navigate('/')}>
            Panhandle Bucks
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}